<!--
 * @Author: Dyf
 * @Date: 2023-02-18 18:24:03
 * @LastEditors: wang hai yang
 * @LastEditTime: 2023-05-16 20:00:09
 * @Description: 学校介绍--编辑
-->
<style lang="scss" scoped>
.page-school--modify {
  @include innerPage;
  @include pageTitle;

  .school-modify {
    width: 100%;
    height: calc(100% - 38px);
    box-sizing: border-box;
    padding: 38px 60px 0 0;

    .modify-form {
      width: 100%;
      height: calc(100% - 84px);
      border-radius: 10px;
      background: #f7f7fa;
      box-sizing: border-box;
      padding: 40px 20px 30px 50px;

      .form-inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 28px;

        .page-form {
          box-sizing: border-box;
          padding-left: 8px;
          padding-right: 16.6%;
        }

        .school-history {
          width: 100%;
          border-top: 1px solid #e0e0e3;
          margin-top: 74px;
          display: flex;
          box-sizing: border-box;
          padding: 44px 12px 0;

          .label {
            width: 68px;
            color: #1b1b1b;
            text-align: right;
            padding-right: 12px;
            box-sizing: border-box;
            line-height: 46px;
          }

          .history {
            &-list {
              flex-grow: 1;
            }

            &-item {
              width: 100%;
              height: 46px;
              margin-bottom: 16px;
              box-sizing: border-box;
              // padding-right: 15%;
              @include flexBox;
              ;

              ::v-deep {

                .el-textarea__inner,
                .el-input__inner {
                  background: #f1f2f7;
                }

                .el-date-editor.el-input {
                  width: 240px;
                  flex-shrink: 0;
                  margin-right: 22px;

                  &__inner {
                    width: 100%;
                  }

                  .el-icon-date:before {
                    content: '';
                  }
                }

                .el-textarea {
                  max-width: 700px;
                  flex-grow: 1;

                  &__inner {
                    width: 100%;
                  }
                }
              }

              .operate-group {
                width: 116px;
                flex-shrink: 0;
                margin-left: 16px;
                @include flexBox;
                ;
              }

              &:last-child {
                margin-bottom: 30px;
              }
            }

            &--add {
              width: 48px;
              height: 48px;
              background: #6c4ecb;
              text-align: center;
              line-height: 48px;
              color: #f5f7fe;
              border-radius: 50%;
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .button-group {
      height: 84px;
      box-sizing: border-box;
      padding-top: 46px;
    }
  }
}
</style>

<template>
  <section class="page-school--modify">
    <div class="page-title">
      <h3>编辑</h3>
      <el-button type="custom_primary" size="small" @click="$router.go(-1)">
        返回
      </el-button>
    </div>
    <div class="school-modify">
      <div class="modify-form">
        <div class="form-inner">
          <el-form class="page-form" :rules="formRules" ref="schoolForm" label-width="68px" :model="schoolForm">
            <el-form-item label="学校名称" prop="scsch_name">
              <el-input v-model.trim="schoolForm.scsch_name" maxlength="100" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="学校logo" class="uploader" prop="scsch_logo">
              <el-upload class="img-uploader" :action="$upload.imgAction" :show-file-list="false"
                :accept="$upload.imgAccept" :before-upload="$beforImgUpload"
                :on-success="(res) => { return uploadSuccess(res, 'scsch_logo') }">
                <div v-if="schoolForm.scsch_logo" class="img-box">
                  <el-image fit="cover" :src="formatfile(schoolForm.scsch_logo)" class="img" />
                  <i class="del iconfont" @click.stop="schoolForm.scsch_logo = ''">&#xe604;</i>
                </div>
                <div class="upload-wrapper" v-else>
                  <span class="iconfont">&#xe603;</span>
                  <p class="tips">支持png/jpg等常规格式</p>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="学校简介" prop="scsch_remark">
              <el-input v-model.trim="schoolForm.scsch_remark" type="textarea" maxlength="200" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="背景图" class="uploader" prop="scsch_images">
              <el-upload class="img-uploader" :action="$upload.imgAction" :show-file-list="false"
                :accept="$upload.imgAccept" :before-upload="$beforImgUpload"
                :on-success="(res) => { return uploadSuccess(res, 'scsch_images') }">
                <div v-if="schoolForm.scsch_images" class="img-box">
                  <el-image fit="cover" :src="formatfile(schoolForm.scsch_images)" class="img" />
                  <i class="del iconfont" @click.stop="schoolForm.scsch_images = ''">&#xe604;</i>
                </div>
                <div class="upload-wrapper" v-else>
                  <span class="iconfont">&#xe603;</span>
                  <p class="tips">支持png/jpg等常规格式</p>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="详细介绍" class="editor">
              <div class="ueditor">
                <!-- <try-tinymce v-model="schoolForm.scsch_content" :width="1200" :height="500" /> -->
                <try-editor v-model="schoolForm.scsch_content" />

              </div>
            </el-form-item>
          </el-form>
          <div class="school-history">
            <p class="label">大事记</p>
            <ul class="history-list">
              <li class="history-item" v-for="(item, index) in schoolForm.memorabilia" :key="index">
                <el-date-picker class="pf" clear-icon :editable="false" type="year" placeholder="选择年份" value-format="yyyy"
                  format="yyyy年" v-model="item.scsch_mem_date" />
                <el-input v-model.trim="item.scsch_mem_content" class="row-1st" type="textarea" maxlength="200"
                  placeholder="输入大事记内容" />
                <div class="operate-group iconfont">
                  <el-button type="custom_primary" circle @click="delHistoryDot(index)">&#xe620;</el-button>
                  <el-button type="custom_primary" circle class="gary" v-if="index < schoolForm.memorabilia.length - 1"
                    @click="moveHistoryDot(index, 'd')">&#xe61f;</el-button>
                  <el-button type="custom_primary" circle class="gary" v-if="index > 0"
                    @click="moveHistoryDot(index, 'u')">&#xe62b;</el-button>
                </div>
              </li>
              <li class="history--add iconfont" @click="addHistoryDot">&#xe603;</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="button-group">
        <el-button type="custom_info" size="small" @click="$router.go(-1)">取消</el-button>
        <el-button type="custom_primary" size="small" @click="handleConfirm" :disabled="btnLoad" v-loading="btnLoad">提交</el-button>
      </div>
    </div>
  </section>
</template>

<script>
import ueditorcom from "@comp/ueditorcom";
import { schoolEdit, schoolInfo } from "@api/school";
import { formatFile } from "@utils";

export default {
  name: "home_schoolModify",
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url)
      }
    },
  },
  components: {
    ueditorcom,
  },
  data() {
    return {
      schoolForm: {
        memorabilia: [{
          scsch_mem_date: '',
          scsch_mem_content: '',
        }]
      }, editor: null,
      formRules: {
        scsch_name: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        scsch_logo: [
          { required: true, message: "请上传学校logo", trigger: "change" },
        ],
        scsch_remark: [{
          required: true, message: "请输入学校简介", trigger: "blur"
        }],
        scsch_images: [
          { required: true, message: "请上传学校背景图", trigger: "change" },
        ],
      },
      btnLoad: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    /** 初始化信息 */
    async init() {
      const res = await schoolInfo();
      if (res.status == 200) {
        let keyArr = ['scsch_name', 'scsch_logo', 'scsch_remark', 'scsch_content', 'scsch_images', 'memorabilia'];
        let schoolForm = {};
        keyArr.map(key => {
          schoolForm[key] = res.data[key] || ''
        })
        this.schoolForm = { ...schoolForm };
      }
    },
    /** 提交编辑 */
    async handleConfirm() {
      this.$refs.schoolForm.validate(async (valid) => {
        if (valid) {
          this.btnLoad = true;
          let arr = [];
          this.schoolForm.memorabilia.map(item => arr.push({ scsch_mem_date: (Date.parse(item.scsch_mem_date) / 1000), scsch_mem_sort: item.scsch_mem_sort, scsch_mem_content: item.scsch_mem_content }));
          this.schoolForm.memorabilia = arr;
          const res = await schoolEdit(this.schoolForm);
          this.btnLoad = false;
          if (res.status == 200) {
            this.$message.success("编辑成功");
            setTimeout(() => {
              // this.$router.push({
              //     name: "HOME_INDEX",
              // });
              window.location.href = window.location.origin
            }, 1500);
          }
        }
      });
    },
    /** 文件上传成功 */
    uploadSuccess({ data, msg }, el) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.schoolForm[el] = data.address;
    },
    /** 添加大事记节点 */
    addHistoryDot() {
      this.schoolForm.memorabilia.push({
        scsch_mem_date: '',
        scsch_mem_content: '',
      });
      this.$forceUpdate();
    },
    /** 移除大事记节点 */
    delHistoryDot(index) {
      let schoolHistory = this.schoolForm.memorabilia;
      this.$_.pullAt(schoolHistory, index)
      this.schoolForm.memorabilia = [...schoolHistory];
      this.$forceUpdate();
    },
    /** 大事记节点移动 */
    moveHistoryDot(index, type) {
      const dot_1 = this.schoolForm.memorabilia[index];
      let dot_2;
      /* 下移 */
      if (type == 'd') {
        dot_2 = this.schoolForm.memorabilia[index + 1];
        this.schoolForm.memorabilia[index + 1] = { ...dot_1 };
      }
      /* 上移 */
      if (type == 'u') {
        dot_2 = this.schoolForm.memorabilia[index - 1];
        this.schoolForm.memorabilia[index - 1] = { ...dot_1 };
      }
      this.schoolForm.memorabilia[index] = { ...dot_2 };
      this.$forceUpdate();
    },
  },
};
</script>